<template>
      
  <div class="zhici_all">
    <header class="zhici_BoDetails">
    <img :src="banners" alt />
  </header>
<div>


<div class="zhici_titles"><img alt="" src="../assets/titleImg.png" />
        <img id="zhici_TitleImg" src="../assets/museumDetails/curatorSpeech.png">
        <img alt="" src="../assets/titleImg.png" />
        </div>
        <div>

<div class="zhici_en_bodyDiv">
      <div class="zhici_Details zhici_mot">
        <p>
          顺义博物馆是顺义区唯一一家区级博物馆，是以展示顺义人文、历史和发展为主，具有地域特色的综合性博物馆，位于顺义区石园街道，石园南大街顺义文化中心内，2021年3月3日正式免费对外开放。
        </p>
        <p>
          顺义博物馆总建筑面积7656平方米，其中地上建筑面积6029平方米，地下建筑面积1627平方米。共分为四个楼层，地下一层设有大型多媒体沙盘及大型藏品库房等；一层设有临展厅、精品厅、休闲区、文化教育实践活动室；夹层为办公区域；二层为顺义历史文化展厅；三层设有会客厅、小型藏品库房、多媒体功能厅、文物修复室及摄影室。下设9个部室，分别为：办公室、财务室、党建室、展览策划部、社教研学部、文保征集部、视宣部、讲解服务岗、巡查应急部。
        </p>
        <p>
          顺义博物馆陈列由常设展览、精品展览和临时展览三部分组成。展厅总面积2000平方米，其中一层设临时展厅，精品厅，二层为常设展厅，展览面积约1000平方米。馆内藏品丰富，囊括了从史前时期至近代以来的珍贵石器、靑铜器、瓷器、陶器、玉器、拓片、金银器、杂器等历经19个朝代的出土文物，并且展示了新中国成立后顺义在农业、工业、文化教育等方面的发展，为大众展现顺义在各个历史时期的面貌与时代特点。
        </p>
        <p>
          开馆以来，顺义博物馆秉持“百姓博物馆”、“文化博物馆”和“休闲博物馆”理念，着力加强数字化建设，提升各项服务水平，努力让进馆的观众感受到在历史长河中徜徉的独特感受。本馆采取开放、自助、人性化的服务模式，努力满足广大公众的文化需求，为公众构筑起一种全新的文化生活方式。每一件藏品背后都有一段历史，每一件文物都是中华文明和顺义文化的讲述者，我们诚邀各界专家学者，人民大众一起探索古今顺义的发展进程。
        </p>
        <p>
          顺义博物馆将不断积聚和提升博物馆的文化服务力和对外影响力，为建设社会主义文化强国贡献力量。
        </p>
      </div>
      <p class="zhici_right" style="textalign: right; color: #bd955d;position: relative;bottom:16px;right: 0;">
        <span class="zhici_Guanzhu">馆长 赵浩成</span> 
      </p>
    </div>
        </div>
    
     
    <Gfoot v-if="flag"></Gfoot>
    </div>
  </div>
</template>

<script>
import Gfoot from "../components/footer";
import { fetch } from "../api/https";

export default {
  data() {
    return {
      banners: "",
    zhi:'',
    flag:false,
    Scales:false
    };
  },
  components: {
    Gfoot,
  },
  created() {},
  mounted() {
    console.log(document.querySelector('.zhici_all').offsetHeight)
    
    fetch(`/menu/${10}`)
      .then((res) => {
        this.zhi = res.data.menu_content;

        this.flag = true;
        document.title = res.data.menu_seo_title;
      })
      .catch((err) => {});
    fetch("/getmenu")
      .then((res) => {
        this.banners = res.data[1].child[1].menu_img;
      })
      .catch((err) => {});
    fetch('/getmenu')
      .then(res => {
        this.banners = res.data[1].child[1].menu_img;
      })
      .catch(err => {});
      var width = document.documentElement.clientWidth;
        var height = document.documentElement.clientHeight;
        if(width>1024 &&  width< 1400){
          this.Scales = true
        }
  },
  methods: {
    
  },
};
</script>

<style scoped>
.zhici_all{
  width: 100%;
  /* height: 100%; */
}
header{
  width: 100%;}
.zhici_imagex{
  width: 100%;
  height: 100%;
}
.zhici_BoDetails {
  width: 100%;
  height: 565px;

  /* background: url("../assets/img/banner5.png"); */
}
.zhici_BoDetails img {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: auto;
}
.zhici_Details {
  font-size: 18px;
  color: #888888;
  line-height: 33px;
  text-align: left;
  width: 1167px;
  line-height: 33px;
  font-weight: Medium;
}
.zhici_all {
  /* overflow: hidden; */
}
.zhici_Details p {
  margin-bottom: 10px;
  text-indent: 2em;
}
.zhici_en_bodyDiv {
  background: url("../assets/img/powuguang/border.png") no-repeat;
  text-align: center;
  max-width: 1400px;
  min-width: 1366px;
  background-size: 100% 100%;
  margin: 0 auto;
  /* background-color: yellow; */
  height: 675px;
  padding: 30px;
  padding-top: 45px;
  padding-bottom: 113px;
  padding-left: 116px;
  padding-right: 117px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 60px;
}
.zhici_titles {
  font-weight: 800;
  font-size: 45px;
  text-align: center;
  margin: 0 auto;
  /* margin-top: 106px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 101px;
  margin-bottom: 41px;
}
.zhici_titles img {
  width: 26px;
  height: 21px;
  margin-left: 14px;
  margin-right: 14px;
}

.zhici_outText {
  color: rgb(188, 148, 93);
  font-weight: 300;
  font-size: 18px;
}
.zhici_mom {
  margin-bottom: 30px;
}
.zhici_right {
  margin-top: 20px;
  position: absolute;
  bottom: 61px;
  right: 116px;
  position:absolute;
  bottom: 61px;
  right: 116px;
  text-align: right;
  font-size: 22px;
  
}
.zhici_mot {
  margin-top: 30px;
}
.zhici_imageList {
  height: 305px;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 1288px;
}
.zhici_Imgs {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.zhici_Imgs img {
  width: 30%;
  height: 100%;
}
footer {
  /* width: 100%; */
  /* height: 270px; */
}
.zhici_cen {
  width: 100%;
  line-height: 48px;
  text-align: left;
  box-sizing: border-box;
}

.zhici_cen p {
  text-indent: 2em;
  /* margin-bottom: 10px; */
}
.zhici_Guanzhu {
  font-size: 32px;
}
#zhici_TitleImg{
  height: 44px;
  width: 178px;
}
@media screen and (max-width: 1400px) {
  .zhici_en_bodyDiv{
    max-width: 1266px;
    box-sizing: border-box;
    min-width: 0;
    
  }
  .zhici_Details p{
    width: 89%;
  }
}
</style>
